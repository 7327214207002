<template>
  <center>
    <h1>Page Ini Hanya Dapat Diakses Oleh Validator!</h1>
    <p>
      <a href="/">Back to Home</a>
    </p>
  </center>
</template>

<script>

  export default {
    name: 'NotFound',
    mounted (){
        this.$session.destroy();
        this.$store.dispatch('logout')
    }
  }

</script>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>